<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('users.items')" />

      <main-table
        :baseURL="API_BASE_URL_SSO"
        :fields="fields"
        :list_url="'admin/admin'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('users.create')"
        createRouteName="administration.userCreate"
        :excelFields="excelFields"
        excelName="users"
        :downloadSampleStatus="true"
        downloadSampleUrl="admin/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="admin/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { API_BASE_URL_SSO } from '../../../../config/constant'
import { core } from '../../../../config/pluginInit'
import UserFilter from '../models/UserFilter'
import searchMixin from '../../../../Mixins/searchMixin'

export default {
  mixins: [searchMixin],
  data () {
    return {
      API_BASE_URL_SSO,
      filter: new UserFilter(),
      fields: [
        'selected',
        { key: 'id', label: this.$t('table.id'), sortable: true },
        { key: 'username', label: this.$t('global.userName'), sortable: true },
        { key: 'email', label: this.$t('global.email'), sortable: true },
        { key: 'phone', label: this.$t('global.phone'), sortable: true },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            {
              text: this.$t('global.view'),
              icon: 'fas fa-eye',
              color: 'info',
              ifNavigate: true,
              routeName: 'administration.userView',
              routeParams: ['id']
            },
            {
              text: this.$t('global.update'),
              icon: 'fas fa-pen',
              color: 'primary',
              ifNavigate: true,
              routeName: 'administration.userUpdate',
              routeParams: ['id']
            },
            {
              text: this.$t('global.delete'),
              icon: 'fas fa-trash-alt',
              color: 'danger',
              showAlert: true,
              actionHeader: this.$t('global.delete'),
              titleHeader: this.$t('users.item'),
              textContent: 'name',
              url: 'admin/admin',
              type: 'delete'
            }
          ]
        }
      ],
      excelFields: {
        [this.$t('table.id')]: 'id',
        [this.$t('global.userName')]: 'user_name',
        [this.$t('global.email')]: 'email',
        [this.$t('global.phone')]: 'phone',
        [this.$t('table.createdAt')]: 'created_at'
      }
    }
  },
  mounted () {
    core.index()
  }
}
</script>
